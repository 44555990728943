const explainer = document.querySelector(".explainer");

if (explainer) {
    let descriptions = [
        {description: "niski wzrost – do 130 cm", x: -0.2, y:-0.01, to: 'always-right'},
        {description: "zwiększenie obwodu głowy", x: 0.63, y:0.07},
        {description: "zawężenie podstawy czaszki", x: 0.24, y:0.28},
        {description: "nadmierne uwypuklenie czoła", x: 0.39, y:0.13},
        {description: "zapadnięcie nasady nosa", x: 0.4, y:0.16},
        {description: "skrzywienie kręgosłupa", x: 0.7, y:0.28},
        {description: "koślawość nóg", x: 0.6, y:0.8},
        {description: "zwężenie klatki piersiowej", x: 0.45, y:0.4},
        {description: "problemy z oddychaniem", x: 0.45, y:0.35},
        {description: "zwiększona ruchomość stawów", x: 0.85, y:0.45},
        {description: "obniżenie napięcia mięśniowego", x: 0.05, y:0.35},
        {description: "przykurcze stawów", x: 0.1, y:0.6},
        {description: "bardzo krótkie palce", x: 0.12, y:0.55},
        {description: "częste infekcje ucha środkowego i związany z tym niedosłuch", x: 0.12, y:0.2},
        {description: "wodogłowie (u niektórych pacjentów), konieczność odprowadzenia płynu", x: 0.53, y:0.04},
    ];


    let imageElement = explainer.getElementsByTagName('img')[0];
    let dotsElement = explainer.querySelector<HTMLElement>('.dots');


    window.addEventListener('resize', () => {
        dotsElement.style.left = imageElement.offsetLeft.toString() + 'px';
        dotsElement.style.top = imageElement.offsetTop + 'px';
        dotsElement.style.width = imageElement.width + 'px';
        dotsElement.style.height = imageElement.height + 'px';
    })

    descriptions.forEach((item) => {

        let tpl = `<div class="point"></div><div class="description"><p>${item.description}</p></div>`;
        let newEl = document.createElement('div');
        newEl.classList.add('dot');
        newEl.style.left = (item.x * 100).toString() + '%';
        newEl.style.top = (item.y * 100).toString() + '%';
        newEl.innerHTML = tpl;

        if(item.x < 0.5)
            newEl.classList.add('to-left');

        if(item.to) {
            if(item.to == 'left')
                newEl.classList.add('to-left');
            else
                newEl.classList.remove('to-left');

            if(item.to == 'always-right') {
                newEl.classList.add('always-right');

            }
        }
        explainer.getElementsByClassName('dots')[0].appendChild(newEl);
    });
}

const childhoodBlock = document.querySelector(".b-Childhood");

if(childhoodBlock) {

    let childHeading = childhoodBlock.querySelector('h2');

    let childScrollHandler = () => {
        if(childHeading.getBoundingClientRect().top < (2*window.innerHeight/3)) {
            childhoodBlock.classList.add('visible');
            document.querySelector('.welcome-box__scroll-down').classList.add('hidden');

            setTimeout(() => {
                document.querySelector('.welcome-box__scroll-down').remove();
            }, 2000)
            window.removeEventListener('scroll', childScrollHandler);
        }
    };

    window.addEventListener('scroll', childScrollHandler);
}


const heightBlock = document.querySelector(".b-Height");

if(heightBlock) {

    let heightContainer = heightBlock.querySelector<HTMLElement>('.container');

    let hcTop = ((window.outerHeight - heightContainer.getBoundingClientRect().height)/2);
    heightContainer.style.top =  hcTop.toString() + 'px';

    let heightScrollHandler = () => {
        if(heightBlock.getBoundingClientRect().top < 0) {
            heightBlock.classList.add('visible');
            if(heightBlock.getBoundingClientRect().bottom - heightContainer.getBoundingClientRect().bottom <= 141 && heightContainer.getBoundingClientRect().top <= hcTop+1) {
                heightBlock.classList.remove('fixed');
                heightBlock.classList.add('bottomed');
                heightContainer.style.top = 'auto';
            } else {
                heightBlock.classList.add('fixed');
                heightBlock.classList.remove('bottomed');
                heightContainer.style.top =  hcTop.toString() + 'px';
            }

        } else {
            heightBlock.classList.remove('fixed');
        }
    };

    window.addEventListener('scroll', heightScrollHandler);
}


const europeBlock = document.querySelector(".b-Europe");

if(europeBlock) {


    let europeScrollHandler = () => {
        if(europeBlock.getBoundingClientRect().top < (window.innerHeight/4)) {
            europeBlock.classList.add('visible');
            window.removeEventListener('scroll', europeScrollHandler);
        }
    };

    window.addEventListener('scroll', europeScrollHandler);

    let imgElement = europeBlock.querySelector<HTMLElement>('.b-Europe__image');
    let imgBaseWidth = 812;
    let imgBaseHeight = 521;
    let imgRatio = 1.56;

    let imgPath = imgElement.querySelector<HTMLElement>('svg g');

    let europeResizeHandler = () => {
        console.log(imgElement.getBoundingClientRect().height / imgElement.getBoundingClientRect().width);
        if(imgElement.getBoundingClientRect().width / imgElement.getBoundingClientRect().height > imgRatio) {
            let transformScaleRatio = imgElement.getBoundingClientRect().width / 812;
            imgPath.style.transform = 'scale(' + transformScaleRatio + ') translateY(' + ((imgElement.getBoundingClientRect().height - 521*transformScaleRatio) / 2)+ 'px)';
        } else {
            let transformScaleRatio = imgElement.getBoundingClientRect().height / 521;
            imgPath.style.transform = 'scale(' + transformScaleRatio + ') translateX(' + (((imgElement.getBoundingClientRect().width - 812*transformScaleRatio) / 2) + 200)+ 'px)';

        }
    };
    window.addEventListener('resize', europeResizeHandler);
}


const everydayBlock = document.querySelector(".b-EveryDay");

if(everydayBlock) {


    let everydayScrollHandler = () => {
        if(everydayBlock.getBoundingClientRect().top < (window.innerHeight/2)) {
            everydayBlock.classList.add('visible');
            window.removeEventListener('scroll', everydayScrollHandler);
        }
    };

    window.addEventListener('scroll', everydayScrollHandler);
}


const understandingBlock = document.querySelector(".b-Understanding");

if(understandingBlock) {


    let understandingScrollHandler = () => {
        if(understandingBlock.getBoundingClientRect().top < (window.innerHeight/4)) {
            understandingBlock.classList.add('visible');
            window.removeEventListener('scroll', understandingScrollHandler);
        }
    };

    window.addEventListener('scroll', understandingScrollHandler);
}

const blocks = document.querySelectorAll('section');
const headerLinks = document.querySelector('.p-header-nav');
let currentElId = null;
const blockPositionChecker = () => {
    let newCurrentElId = null;
    blocks.forEach( (blockElement) => {
        if(blockElement.getBoundingClientRect().top < (window.innerHeight/2)  && blockElement.getBoundingClientRect().bottom > (window.innerHeight/2))
            newCurrentElId = blockElement.id;
    });


    if(newCurrentElId != currentElId) {
        const oldActiveNavEl = headerLinks.querySelector('a[href=\'#' + currentElId + '\']');
        if(oldActiveNavEl)
            oldActiveNavEl.classList.remove('active');

        const newActiveNavEl = headerLinks.querySelector('a[href=\'#' + newCurrentElId + '\']');
        if(newActiveNavEl)
            newActiveNavEl.classList.add('active');

        currentElId = newCurrentElId;
    }


}

headerLinks.querySelectorAll('a').forEach((el) => {
    el.addEventListener('click', () => {
        headerLinks.parentElement.querySelector('input').checked = false;
    });
});

window.addEventListener('scroll', blockPositionChecker);

window.dispatchEvent(new Event('resize'));
window.dispatchEvent(new Event('scroll'));